.header {
  width: 100%;
  height: var(--height-header);
  border-bottom: 1px solid var(--colour-header-border);
  
  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-size: 2.5rem;
    font-family: 'Grenze Gotisch', serif;
  }
  
  button {
    --size-btn: 3rem;
    --size-svg: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size-btn);
    height: var(--size-btn);
    cursor: pointer;

    svg {
      width: var(--size-svg);
      height: var(--size-svg);
    }
    
    &.is-dark-mode,
    &.dev-modal-trigger {
      --size-svg: 1.15rem;
    }

    &.dev-modal-trigger {
      display: none;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--max-width-game);
  margin: 0 auto;
  padding: var(--padding-game-container);
}

.buttons-wrapper {
  display: flex;
}