@import "animations";

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 1rem;
}

.board,
.row {
  display: grid;
  grid-gap: 0.25rem;
}

.board,
.row,
.tile {
  width: 100%;
}

.board {
  margin: 0 auto;
  grid-template-rows: repeat(6, 1fr);
}

.row[data-animation="shake"] {
  animation: shake 500ms ease-out forwards;
}

.tile {
  --tile-border-width: 2px;
  --tile-bottom-border-inset: calc(var(--tile-border-width) * -1);
  --tile-bottom-border-opacity: 0;
  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  border: var(--tile-border-width) solid var(--colour-tile-border-default);
  border-radius: 0.35rem;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: bold;
  font-size: var(--font-size-xs, 2rem);

  &::after {
    content: "";
    position: absolute;
    top: var(--tile-bottom-border-inset);
    bottom: var(--tile-bottom-border-inset);
    left: var(--tile-bottom-border-inset);
    right: var(--tile-bottom-border-inset);
    border-radius: inherit;
    background-color: rgba(0, 0, 0, var(--tile-bottom-border-opacity));
    clip-path: polygon(0% 95%, 100% 95%, 100% 100%, 0% 100%);
  }

  @supports not (aspect-ratio: 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  @media (min-width: 31.25em) {
    font-size: 2rem;
  }

  &[data-status]:not([data-status="bardle"]):not([data-status="falstaff"]):not([data-resolved="true"]) {
    animation: reveal 500ms ease-in forwards;
  }

  &[data-status="falstaff"] {
    animation: pop 150ms ease-in forwards;
  }

  &[data-status="bardle"],
  &[data-resolved="true"] {
    --tile-bottom-border-opacity: 0.15;

    color: #fff;
    background-color: var(--colour-status-bg);
    border-color: var(--colour-status-bg);
  }

  &[data-status="bardle"] {
    animation: celebrate 300ms ease-in forwards;
  }

  &[data-status="hamlet"],
  &[data-status="bardle"] {
    --colour-status-bg: var(--colour-status-correct);
  }

  &[data-status="juliet"] {
    --colour-status-bg: var(--colour-status-present);
  }

  &[data-status="iago"] {
    --colour-status-bg: var(--colour-status-absent);
  }
}

.example {
  margin-top: 1.25rem;

  .row {
    width: 80%;
  }

  p {
    margin: 0.5rem 0 0;
  }

  span {
    font-weight: bold;
  }
  
  [data-status] {
    color: #fff;
    background-color: var(--colour-status-bg, transparent);
    border-color: var(--colour-status-bg, var(--colour-tile-border-filled));
    animation: none !important;
    
    &[data-status=""] {
      color: var(--colour-text-app);
    }
  }
}