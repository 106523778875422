.keyboard {
  width: 100%;
  max-width: 28rem;
  margin: 1rem auto 0 auto;
}

.row {
  display: flex;
  margin-top: 0.25rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.key {
  --outline-offset: 1px;

  flex: 1;
  border: none;
  border-radius: 0.35rem;
  margin-left: 0.25rem;
  padding: 0 0.25rem;
  height: 3.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--colour-kb-text-default);
  background-color: var(--colour-kb-bg-default);
  
  &:first-of-type {
    margin-left: 0;
  }

  &[data-status]:not([data-status="default"]) {
    color: #fff;
  }

  &[data-status="hamlet"] {
    background-color: var(--colour-status-correct);
  }

  &[data-status="juliet"] {
    background-color: var(--colour-status-present);
  }

  &[data-status="iago"] {
    background-color: var(--colour-status-absent);
  }
}