@import "animations";

.container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 2.5rem;
}

.toast {
  border-radius: 0 0 0.35rem 0.35rem;
  overflow: hidden;
  animation: dropDown 2000ms ease-out forwards;
  font-weight: bold;
  color: #fff;

  &--win {
    background-color: var(--colour-toast-bg-win);
  }

  &--lose {
    background-color: var(--colour-toast-bg-lose);
  }
  
  &--error {
    background-color: var(--colour-toast-bg-error);
  }
  
  p {
    margin: 0.5rem;
  }
}