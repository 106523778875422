@import "animations";

.container,
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container,
.content {
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &[aria-hidden="true"] {
    display: none;
  }
}

.overlay {
  background-color: rgba(43, 46, 56, 0.9);
}

.content {
  position: relative;
  overflow-y: auto;
  min-height: 60%;
  max-height: 95%;
  width: 90%;
  max-width: calc(var(--max-width-game) * 0.8);
  border-radius: 0.35rem;
  padding: 1.25rem 1rem;
  background-color: var(--bg-app);
  animation: float-up 300ms ease-out forwards;

  display: flex;
  flex-direction: column
}

.title {
  margin: 0;
  letter-spacing: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  align-self: end;
  width: 3.5rem;
  height: 4rem;
}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1.25rem;
  font-size: 0.875rem;

  > *:first-child {
    margin-top: 0;
  }

  p {
    margin: 1rem 0 0;
  }
}

.main-content {
  flex-grow: 1;
}

.stats-table {
  table-layout: fixed;
  width: 100%;

  th,
  td {
    text-align: center;
    vertical-align: bottom;
  }

  th {
    font-size: 0.8rem;
    font-weight: normal;
  }

  td {
    font-size: 1.75rem;

    @media (min-width: 31.25em) {
      font-size: 2.25rem;
    }
  }
}

.stats-histogram {
  width: 100%;
  margin: 1.5rem 0 2rem;
  height: 14.5rem;
  border-spacing: 0.25rem;

  caption {
    margin-bottom: 0.5rem;
    letter-spacing: 0.05rem;
  }

  th {
    width: 1rem;
  }

  td {
    display: flex;
    align-items: center;
    min-width: 1.5rem;
    height: 100%;
    color: #fff;
    font-weight: normal;
    padding-left: 0.5rem;
    background-color: var(--colour-status-correct);
  }
}

.solution-wrapper {
  --colour-link: #fff;
  --colour-link-hover: #000;
  --colour-link-bg-hover: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 0.35rem;
  margin-top: auto;
  padding: 1.5rem;
  color: #fff;
  background-color: var(--colour-status-absent);
}

.solution {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.25rem;
}

.clock-and-share-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    &:first-child {
      border-right: 1px solid var(--colour-text-app);
    }
  }

  p {
    margin: 0;
  }
}

.clock {
  font-size: 1.75rem;
  font-weight: bold;
}

.share,
.dev-button {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  border-radius: 0.35rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  background: var(--colour-status-correct);
  color: #fff;

  @media (hover: hover) {
    &:hover {
      background: var(--colour-status-present);
    }
  }

  &:active {
    background: var(--colour-status-present);
  }

  span {
    margin-right: 0.5rem;
  }
}

.clock,
.share {
  margin: 0 0.5rem;
}

.dev-button {
  justify-content: center;
  margin-top: 1.25rem;
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  a {
    font-size: 1.75rem;
  }

  span {
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0.25rem;
  }
}