@import "variables";

* {
  box-sizing: border-box;
}

:is(a, button, input, label, textarea, summary):focus {
    outline: none !important;
    box-shadow: none !important;
}

.is-kb-nav {
  :is(a, button, input, label, textarea, summary) {
    --outline-size: max(4px, 0.08em);
    --outline-style: dotted;
    --outline-color: var(--colour-status-present);
    --outline-offset: -5px;

    &:focus-visible {
      outline: var(--outline-size) var(--outline-style) var(--outline-color) !important;
      outline-offset: var(--outline-offset) !important;
    }
  }
}

html,
body {
  background-color: var(--bg-app);
  color: var(--colour-text-app);
}

button {
  border: none;
  padding: 0;
  color: var(--colour-text-app);
  background: none;
  cursor: pointer;
}

a {
  color: var(--colour-link);
  transition: all 150ms ease-in-out;

  &:hover {
    color: var(--colour-link-hover);
    background: var(--colour-link-bg-hover, transparent);
  }
}

.hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}