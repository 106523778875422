.game {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-width-game);
  height: var(--height-game);
  margin: 0 auto;
  padding: var(--padding-game-container);
  padding-bottom: 0.5rem;
}