:root {
  --bg-app: #fff;
  --colour-text-app: #000;
  --colour-status-correct: #4f799a;
  --colour-status-present: #ce7106;
  --colour-status-absent: #787c7e;
  --colour-kb-bg-default: #d3d3d3;
  --colour-kb-text-default: #000;
  --colour-tile-border-default: #d3d6da;
  --colour-tile-border-filled: #000;
  --colour-header-border: #d3d6da;
  --colour-link: #000;
  --colour-link-hover: #fff;
  --colour-link-bg-hover: #000;
  --colour-toast-bg-win: var(--colour-status-correct);
  --colour-toast-bg-lose: var(--colour-status-absent);
  --colour-toast-bg-error: #a72222;
  --max-width-game: 32rem;
  --height-header: 3rem;
  --height-game: calc(100% - var(--height-header));
  --padding-game-container: 0 0.25rem;
}

[data-theme="dark"] {
  --bg-app: #0e141b;
  --colour-text-app: #fff;
  --colour-kb-bg-default: #818384;
  --colour-kb-text-default: #fff;
  --colour-tile-border-default: #3a3a3c;
  --colour-tile-border-filled: #565758;
  --colour-status-present: #dd7600;
  --colour-status-absent: #5b5b5b;
  --colour-link: #fff;
  --colour-link-hover: #121213;
  --colour-link-bg-hover: #fff;
}