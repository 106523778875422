@keyframes reveal {
  0%,
  100% {
    transform: rotateY(0);
  }

  45% {
    transform: rotateY(-90deg);
  }

  55%,
  100% {
    --tile-bottom-border-opacity: 0.15;
    
    color: #fff;
    background-color: var(--colour-status-bg);
    border-color: var(--colour-status-bg);
  }

  55% {
    transform: rotateY(-90deg);
  }
}

@keyframes celebrate {
  0%,
  100% {
    transform: translateY(0);
  }

  45%,
  55% {
    transform: translateY(-1.5rem);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes pop {
  0%,
  100% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.2);
  }
  
  100% {
    border-color: var(--colour-tile-border-filled);
  }
}

@keyframes float-up {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(2rem);
  }
  
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes dropDown {
  0%, 100% {
    flex: 0;
  }

  10%,
  80% {
    flex: 1;
  }
}